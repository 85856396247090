import { Box, Typography } from "@material-ui/core"
import React from "react"
import {
  CONTAINER_PADDING,
  MOBILE_X_PADDING,
} from "../../gatsby-theme-material-ui-top-layout/theme"
import { useMobile } from "../../utils/hooks"
import SectionContainer from "../layout/SectionContainer"

const TitleAndParagraphSection = ({
  title,
  maxWidth,
  paragraph,
  bgcolor,
  mb,
  mt,
  button,
}) => {
  const mobile = useMobile()

  return (
    <SectionContainer
      bgcolor={bgcolor}
      flexDirection="column"
      alignItems="center"
      maxWidth={maxWidth}
      mt={mt}
      mb={mb}
    >
      <Typography component="h1" variant={mobile ? "h2" : "h1"} align="center">
        {title}
      </Typography>

      <Box
        mt={mobile ? CONTAINER_PADDING / 2 : CONTAINER_PADDING}
        maxWidth={mobile ? undefined : "70%"}
      >
        <Typography align="center">{paragraph}</Typography>
      </Box>

      {button && (
        <Box
          display="flex"
          justifyContent="center"
          pt={mobile ? CONTAINER_PADDING / 2 : CONTAINER_PADDING}
        >
          {button}
        </Box>
      )}
    </SectionContainer>
  )
}

export default TitleAndParagraphSection
