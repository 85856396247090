import { Box, Button, Grid, TextField, Typography } from "@material-ui/core"
import { navigate } from "gatsby"
import React from "react"
import { useForm } from "react-hook-form"
import { CONTAINER_PADDING } from "../../gatsby-theme-material-ui-top-layout/theme"
import SectionContainer from "../layout/SectionContainer"
import {
  useNetlifyForm,
  NetlifyFormProvider,
  NetlifyFormComponent,
  Honeypot,
} from "react-netlify-forms"
import { useMobile } from "../../utils/hooks"

const required = {
  required: "Pole wymagane",
}

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i

// TODO netlify form - https://pyrax.github.io/react-netlify-forms/examples/react-hook-form/

const SignInFormSection = ({ title, formContainerId }) => {
  const { register, handleSubmit, reset, errors } = useForm({ mode: "onBlur" })
  const netlify = useNetlifyForm({
    name: "contact",
    action: "/",
    honeypotName: "bot-field",
    onSuccess: (response, context) => {
      console.log("Successfully sent form data to Netlify Server")
      //context.formRef.current.reset()
    },
  })
  const onSubmit = data => netlify.handleSubmit(null, data)
  const mobile = useMobile()
  return (
    <NetlifyFormProvider {...netlify}>
      <NetlifyFormComponent onSubmit={handleSubmit(onSubmit)}>
        {({ handleChange, success, error }) => (
          <SectionContainer
            id={formContainerId}
            flexDirection="column"
            alignItems="center"
            my={CONTAINER_PADDING * 2}
          >
            <Typography
              compoennt={"h2"}
              variant={mobile ? "h2" : "h1"}
              align="center"
            >
              {title}
            </Typography>

            <Box mt={CONTAINER_PADDING} maxWidth={mobile ? undefined : "70%"}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={12}>
                  <TextField
                    name="brandName"
                    label="Nazwa projektu"
                    fullWidth
                    inputRef={register(required)}
                    error={errors.brandName}
                    helperText={errors?.brandName?.message}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    name="name"
                    label="Imię"
                    fullWidth
                    inputRef={register(required)}
                    error={errors.name}
                    helperText={errors?.name?.message}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="surname"
                    label="Nazwisko"
                    fullWidth
                    inputRef={register(required)}
                    error={errors.surname}
                    helperText={errors?.surname?.message}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="social"
                    label="Social media"
                    fullWidth
                    inputRef={register(required)}
                    error={errors.social}
                    helperText={errors?.social?.message}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="email"
                    label="E-mail"
                    fullWidth
                    inputRef={register({
                      required: "Pole wymagane",
                      pattern: {
                        value: EMAIL_REGEX,
                        message: "Podaj poprawny adres",
                      },
                    })}
                    error={errors.email}
                    helperText={errors?.email?.message}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    multiline
                    rows={4}
                    name="description"
                    label="Opis zgłaszanego projektu"
                    fullWidth
                    inputRef={register(required)}
                    error={errors.description}
                    helperText={errors?.description?.message}
                  />
                </Grid>

                {netlify.success && (
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      Thanks for contacting us!
                    </Typography>
                  </Grid>
                )}
                {netlify.error && (
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      Sorry, we could not reach servers. Because it only works
                      on Netlify, our GitHub demo does not provide a response.
                    </Typography>
                  </Grid>
                )}

                <Grid item xs={12} container justify="center">
                  <Button
                    fullWidth={mobile}
                    variant="contained"
                    color="primary"
                    size="large"
                    type={"submit"}
                  >
                    Zgłoś projekt
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </SectionContainer>
        )}
      </NetlifyFormComponent>
    </NetlifyFormProvider>
  )
}

export default SignInFormSection
