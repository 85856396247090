import { Box, Button, Container, Grid, Hidden } from "@material-ui/core"
import React from "react"

import { makeStyles } from "@material-ui/core"
import {
  CONTAINER_PADDING,
  YELLOW_BG,
} from "../gatsby-theme-material-ui-top-layout/theme"
import TitleAndParagraphSection from "../components/sections/TitleAndParagraphSection"
import SignInFormSection from "../components/sections/SignInFormSection"
import Layout from "../components/layout/Layout"
import GatsbyImage from "../components/common/GatsbyImage"
import { hiddenOnDesktop, hiddenOnMobile } from "../utils/constans"
import { ArrowDownward } from "@material-ui/icons"

const useStyles = makeStyles(theme => ({
  imagesSection: {
    marginTop: `-${theme.spacing(CONTAINER_PADDING * 2)}px`,
    zIndex: 2,
  },
}))

const formContainerId = "form-netlify-5434u7538"
const DlaProjektantow = () => {
  const classes = useStyles()

  const jumpToForm = () => {
    const form = document.getElementById(formContainerId)
    form && form.scrollIntoView({ behavior: "smooth" })
  }
  return (
    <Layout>
      <TitleAndParagraphSection
        title="Rozwiń swoją twórczość"
        paragraph={`Pokaż nam swoją markę i plan nowej kolekcji, a pomożemy ci w krótkim czasie zebrać potrzebne środki. Skontaktuj się z nami.`}
        bgcolor={YELLOW_BG}
        maxWidth={"md"}
        mt={CONTAINER_PADDING}
        mb={CONTAINER_PADDING * 3}
        button={
          <Box width={250}>
            <Button
              endIcon={<ArrowDownward />}
              variant="contained"
              color="primary"
              fullWidth
              onClick={jumpToForm}
            >
              Wyślij zgłoszenie
            </Button>
          </Box>
        }
      />

      <Container>
        <Box
          display="flex"
          maxWidth="100%"
          alignItems="center"
          justifyContent="center"
          className={classes.imagesSection}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={2}
              md={2}
              container
              alignItems="center"
              component={Hidden}
              implementation="css"
              {...hiddenOnMobile}
            >
              <GatsbyImage
                filename={"dla-projektanta-lewe_mlvojb.jpg"}
                style={{ maxHeight: "80%", height: "100%" }}
              />
            </Grid>

            <Grid item xs={12} md={8} container>
              <GatsbyImage
                filename={"dla-projektanta-center_klwydg.jpg"}
                style={{ maxHeight: 600 }}
                imgStyle={{ objectPosition: "top center" }}
              />
            </Grid>

            <Grid
              item
              xs={2}
              md={2}
              container
              alignItems="center"
              component={Hidden}
              implementation="css"
              {...hiddenOnMobile}
            >
              <GatsbyImage
                filename={"dla-projektantra-r_aevyny.jpg"}
                style={{ maxHeight: "80%", height: "100%" }}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>

      <SignInFormSection
        formContainerId={formContainerId}
        title="Wyślij zgłoszenie"
      />

      <GatsbyImage filename={"projektant_uflopr.png"} />
    </Layout>
  )
}

export default DlaProjektantow
